@charset "UTF-8";
@import './normalize';

* {
  box-sizing: border-box;
  font-stretch: normal;
  font-style: normal;
  &::before,
  &::after {
    box-sizing: border-box;
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6, 
pre, 
//code,
form,
fieldset,
legend,
textarea,
//p,
i,
blockquote,
th,
td,
input,
select,
textarea,
button,
header,
nav,
menu,
section,
article {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font-style: normal;
}
.MuiInputBase-input-MuiOutlinedInput-input{
  padding: 12px 14px;
}
html {
  width: 100%;
  //scroll-behavior: smooth;
}

body {
  padding: 0 !important;
  //cursor: url('../../../usp/public/images/common/mouse action_defualt.png'),
  // auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &.layout--space{
    background-color: #1f2437;
    height: calc(100% - 80px);
  }
  &::-webkit-scrollbar {
    //display: none;
  }
}


ul,
ol,
dl {
  list-style: none;
}

button,a,Select,Input,label,textarea,.MuiTypography-root,.MuiButton-root,.MuiChip-label,.MuiSelect-select {  font-family: 'Noto Sans CJK KR', 'Roboto' !important;}
.MuiFormControlLabel-root{
  margin-left: 0;
}
button,
a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  // cursor: url('../../../usp/public/images/common/mouse action.png'), auto !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.MuiTypography-root {
  margin-top: 0;
  font-stretch: normal;
  font-style: normal;
}

h1 {
  font-weight: 700;
  letter-spacing: -1.92px;
  font-size: 48px;
}

// .mainfull {
//   height: 100vh;
// }

// .MuiPopover-root {position: absolute !important;z-index: 1 !important;}

header {
  position: fixed;
  z-index: 99;
  width: 100%;
  &.subpage {
    nav {
      button {
        color: #222 !important;
        font-weight: 500 !important;
      }
    }
  }
}

.mo {
  display: none !important;
}

.pc {
  display: block !important;
}

.container {
  padding-bottom: 120px;
}

.benner .content {
  padding-bottom: 60px;
}

.content_body .content {
  padding: 60px 15px 0;
  &.list{
    padding: 60px 0 0;
  }
}

.content_body02 .content {
  padding: 100px 15px 0;
}
// .benner .content {min-height: 273px;}

// selectBox 스크롤 오류 임시
// .css-10nakn3-MuiModal-root-MuiPopover-root-MuiMenu-root{
//   position: absolute !important;
//   z-index: 1 !important;
// }

//정렬 margin
.w100{width:100%;}
.tac{text-align: center !important;justify-content: center;}
.tal{text-align: left !important;justify-content: left;}
.tar{text-align: right !important;justify-content: right !important;
  .MuiOutlinedInput-root{
    input{
      text-align: right !important;
    }
  }
}

.m0{margin:0;}
.mt-0{margin-top: 0 !important;}
.mt-10{margin-top: 10px !important;}
.mt-20{margin-top: 20px !important;}
.mt-30{margin-top: 30px !important;}
.mt-40{margin-top: 40px !important;}
.mt-50{margin-top: 50px !important;}
.mt-60{margin-top: 60px !important;}
.mb-10{margin-bottom: 10px !important;}

.pl-10{padding-left: 10px !important;}
.pl-20{padding-left: 20px !important;}
.pl-30{padding-left: 30px !important;}

.fnt-16{font-size: 16px !important;}
.fnt-18{font-size: 18px !important;}
.fnt-19{font-size: 19px !important;}
.fnt-20{font-size: 20px !important;}

.must {color:#1ccdcc;align-self: flex-start;line-height: 30px;padding-left: 3px;}
.stars{
  align-items: baseline;
  &:after{
    content:'*';
    display: inline-block;
    width: 5px;
    height: 5px;
    margin-left: 5px;
    color: #1CCDCC;
  }
}
.color_primary {color:#4063ec}
.color_gray {color:#707070;}

.disBlock {display: block;}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

//체크박스
.checkbox {
  .MuiCheckbox-root {
    .MuiSvgIcon-root {
      width: 20px;
      height: 20px;
      background-color: #fff;
      border-radius: 4px;
      path {
        display: none;
      }
    }
    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      width: 20px;
      height: 20px;
      margin: -10px 0 0 -10px;
      border-radius: 3px;
      border: 1px solid #ccc;
    }
    &.Mui-checked {
      &:before {
        border: none;
        background: url('../images/checkbox_active.png');
      }
    }
  }

  &.h4 {
    .MuiTypography-root {
      font-weight: 700;
      font-size: 18px;
    }
  }
}

.bullt{
  padding-right: 12px;
  position: relative;
  &:before{content:""; width:4px; height: 4px; background-color: #707070; display: inline-block; position:absolute; border-radius:5px; top: 10px; left:0;}
}

@media (min-width: 320px) and (max-width: 768px) {
  .mo {
    display: block !important;
  }

  .pc {
    display: none !important;
  }

  .checkbox {
    .MuiCheckbox-root {
      padding-left: 12px;
    }

    .MuiTypography-root {
      font-size: 14px;
    }

    &.h4 {
      .MuiTypography-root {
        font-size: 14px;
        letter-spacing: -1px;
      }
    }
  }
}

// GNB 하위메뉴
// #demoMenu{
//   .MuiPopover-paper{
//     border-radius: 10px;
//     box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
//     margin-top: 5px;
//     ul{
//       padding: 15px 5px;
//       li{
//         justify-content: center;
//         letter-spacing: -0.32px;
//         &:hover, &:active{
//           color: #4063ec;
//           text-decoration: underline;
//         }
//       }
//     }
//   }
// }
.fp-auto-height {
  height: auto !important;
}